.packages-screen__wrapper {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--contrast), 0.1);
  backdrop-filter: blur(1rem);
  display: grid;
  place-items: center;
}

.packages-screen {
  min-width: 300px;
  background-color: rgb(var(--bg));
  border-radius: 2rem;
  padding: 1rem;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.3);
  a {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.packages-screen__top-row {
  display: flex;
  justify-content: flex-end;
}

.packages-screen__row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.packages-screen__packages {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  border-radius: 2.5rem;
  margin-bottom: 0.5rem;
  background-color: rgba(var(--contrast), 0);

  .pfp {
    margin-bottom: 0.5rem;
  }
}

.sign-out-button {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 2.5rem;
  margin: 0 0.5rem 0 0;
  display: grid;
  place-items: center;
  svg {
    fill: rgb(var(--contrast));
  }
}

.packages-wrapper {
  margin-top: 0rem;
}
.package {
  border: solid 1px rgba(var(--contrast), 0.2);
  border-radius: 0.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  padding: 1rem;
  .accent-button {
    width: 9ch;
  }
}

.packages-wrapper__tokens {
  margin: 0 0 1rem 0;
}
