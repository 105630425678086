@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}
@media (prefers-color-scheme: light) {
  :root {
    --bg: 242, 242, 242;
    --contrast: 31, 31, 31;
    --accent: 30, 144, 255;
    --red: #ff6347;
    --green:#00AA55;
  }
}
@media (prefers-color-scheme: dark) {
  :root {
    --bg: 31, 31, 31;
    --contrast: 242, 242, 242;
    --accent: 0, 191, 255;
    --red: #e73c4e;
    --green:#1cd277;
  }
}
html {
  --font:sans-serif;
  position:fixed;
 height: 100%;
  min-height: 100%;
}

body {
 
  color: rgb(var(--contrast));
  height: 100%;
}
button {
  background-color: rgba(var(--contrast), 0.1);
  border: none;
  font-size: 1rem;
  color: rgb(var(--contrast));
  cursor: pointer;
  &:hover {
    background-color: rgba(var(--contrast), 0.2);
  }
}
a {
  color: rgb(var(--accent));
}

h1 {
  font-weight: 400;
}
#root{

  
    width: 100vw;
    height: 100%;
    overflow: hidden;
    display: flex;
}


.pfp {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgb(var(--contrast));
}
.close-button {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  line-height: 0;
  text-decoration: none;
}
.accent-button {
  height: 2.5rem;
  border-radius: 2.5rem;

  background-color: rgb(var(--accent));
  color: rgb(var(--bg));
  box-shadow: 0 0.25rem 0.5rem rgba(var(--accent), 0.3);
  &:hover {
    background-color: rgba(var(--accent), 0.8);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
 
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body,
button {
  font-family: var(--font);
}

main {
  display: flex;
  position: relative;
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

ul {

  padding-left: 20px;
}

li{
  margin-bottom: .5rem;
}
ol {
  list-style: none; /* Hide default numbers */
  counter-reset: item; /* Create a new counter */
  padding-left: 20px; /* Adds indentation */
}

ol li {
  counter-increment: item; /* Increment the counter for each list item */
  position: relative;
  padding-left: 30px; /* Create space for the custom number */
}

ol li::before {
  content: counter(item) "."; /* Display the counter value and a dot */
  position: absolute;
  left: 0;
  color: rgb(var(--contrast)); /* Set the custom number color to white */
  /* Adjust top value as needed to align vertically */
}
.chat-bot__response-text {
  h1,
  h2,
  h3 {
    margin: 1rem 0;
  }
  h1 {
    font-size: 1.2rem;
    font-weight: bold;
    color: rgb(var(--accent));
    margin-top: 2rem;
   
  }
  h2 {
    font-size: 1.25rem;
    font-weight: bold;
  
    color: rgb(var(--contrast));
  }
  h3 {
    font-size: 1rem;
    font-style: italic;
    color: rgb(var(--contrast));
  }
}

strong {
  font-weight: bold;
  color: rgb(var(--accent));
}
ol,
ul {
  margin: 0.5rem 0;
}


/*TEMP*/

.elapsed-time-container{position:absolute;background-color: red;left:40%;display: none;}
.bug-check{
  position:absolute;
  z-index: 1;
  display: none;
}