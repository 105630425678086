


@media (orientation: landscape) {

  .pdf-wrapper {

    min-height: calc(100% - 11rem);
 
  }
}

@media (orientation: portrait) {
  .pdf-wrapper {

    min-width: calc(100% - 4rem);
 
  }
}

.pdf-wrapper {
  position: absolute;
  z-index: 2;
  bottom: 10rem;
  
  
  max-height: calc(100% - 11rem);
  max-width: calc(100% - 4rem);
  aspect-ratio: 8.5/11;
  object-fit: cover;
  box-shadow: 0 0.5rem 1.25rem rgba(0, 0, 0, 0.1);
}


.pdf-wrapper.open {
  display: flex;
}
.pdf-wrapper.closed {
  display: none;
}

.react-pdf__Document {
  position: relative;
  display: flex;
  justify-content: center;
}

.page-controls {
  position: absolute;
  z-index: 2;
  bottom: 0.5rem;
  background-color: rgb(var(--bg));
  display: flex;
  align-items: center;

  border-radius: 0.25rem;
}

.page-controls button {
  padding: 0.5rem;
}

.page-controls p {
  margin: 0 0.5rem;
}


.react-pdf__Document a  {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 2;

}

.react-pdf__Document a .close-button {

  background-color: rgb(var(--bg));
}

.react-pdf__Document .close-button:nth-child(3) {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;
  background-color: rgb(var(--bg));
}
