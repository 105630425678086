@keyframes mokcUpSlides {
    0% {
      transform: translateY(15%);
      opacity: 0;
    }
    2% {
      transform: translateY(0%);
      opacity: 1;
    }
    48% {
      transform: translateY(0%);
      opacity: 1;
    }
  
    50% {
      transform: translateY(15%);
      opacity: 0;
    }
    100% {
      transform: translateY(15%);
      opacity: 0;
    }
  }


  @keyframes fillFont {
    0% {
      clip-path: inset(100% 0% 0% 0%);
    
    }
   
    100% {
      clip-path: inset(0% 0% 0% 0%);
     
    }
  }

   @keyframes fillFont {
    0% {
      clip-path: inset(100% 0% 0% 0%);
    
    }
   
    100% {
      clip-path: inset(0% 0% 0% 0%);
     
    }
  }


  @keyframes fadeOut {
    0% {
     opacity: 1;
    
    }
   
    100% {
     opacity: 0;
     pointer-events: none;
    }
  }
  
  

  .landing-wrapper{
    height: 100vh;
    width:100vw;
    overflow-y: auto;
    background: linear-gradient(
      90deg,
      rgba(var(--bg), 0.8) 0%,
      rgba(var(--bg), 0.8) 100%
    ),
    url("../../images/bg.webp");
  background-size: cover;
  background-position: center;
  position: relative;
  }

  #passInput{
display: block;
width: 200px;
margin:auto;
position: relative;
top:50vh;
  }

   .sign-in__wrapper {
    width: 100vw;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  
  .sign-in__top-bar {
    position: fixed;
    top: 0;
    z-index: 2;
    background-color: rgba(var(--bg), 0.5);
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 1rem);
    padding: 0.5rem;
    height: 2.5rem;
  }
  
  .sign-in__logo {
    display: flex;
    align-items: center;
  
  
    svg {
      height: 80%;
   
     
      fill: rgb(var(--contrast));
    
    }
  }
  #logo{
    width: 2.5rem;
    height: 2.5rem;
    margin:0 1rem 0 0;
    display: grid;
    place-items: center;
  }
  .sign-in__link {
    height: 2.5rem;
  
    border-radius: 2.5rem;
    padding: 0 1rem;
    background-color: rgba(var(--contrast), 0.1);
    border: none;
    font-size: 1rem;
    color: rgb(var(--contrast));
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  
    &:hover {
      background-color: rgba(var(--contrast), 0.2);
    }
  }
  
  .create-account__link {
    font-size: 1.3rem;
    height: 2.5rem;
    margin: 1rem 0 0;
    border-radius: 2.5rem;
    padding: 0 1rem;
  
    border: none;
    
  font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(var(--accent), 0);
  border:solid 1px  rgb(var(--accent));
    color: rgb(var(--accent));
    width: 301px;
    &:hover {
      background-color: rgba(var(--accent), 0.2);
    }
  }
  
  .sign-in__hero-wrapper {
    display: flex;
    padding: 2rem;
    min-height: calc(100vh - 4rem);
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
  
  .mockup-wrapper {
    position: relative;
    border-radius: 1.25rem;
    max-height: 76vh;
    max-width: calc(100% - 2rem);
    aspect-ratio: 9 / 16;
    overflow: hidden;
    box-shadow: 0 0.5rem 1.25rem rgba(0, 0, 0, 0.1);
  }
  
  .mockup-bg {
    height: 100%;
    width: 100%;
  }
  
  .mockup-dialog {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    --duration: 10;
  }
  
  .mockup-dialog.one {
    opacity: 0;
    animation: mokcUpSlides 20s ease infinite forwards;
  }
  
  .mockup-dialog.two {
    opacity: 0;
    animation: mokcUpSlides 20s ease 10s infinite forwards;
  }
  


  button.create-account__link>svg{
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;
  }


  svg#facebook{
    fill: #1877f2;
  }
  
  .sign-in__header {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  
    color: rgb(var(--contrast));
    height: 66vh;
    max-width: 37ch;
  


  }

  .sign-in__copy-medium {

    font-size: 1.3rem;
    margin: 0 0 0.5rem;
    font-weight: 700;
  }
  
  .sign-in__auth-wrapper {
    position: relative;
    z-index: 1;
    margin: 1rem;
    background-color: rgb(var(--bg));
    border-radius: 2rem;
    padding: 2rem;
    box-shadow: 0 0.5rem 1.25rem rgba(0, 0, 0, 0.1);
  
    .auth-container {
      display: flex;
      flex-direction: column;
      .auth-container:first-child > div {
        display: flex;
        flex-direction: column;
        margin: 0 0 1rem;
      }
      .auth-container:last-child {
        display: flex;
        flex-direction: column;
        a {
          margin: 0 0 0.5rem 0;
        }
      }
    }
  }


  .about-us-wrapper {
    display: flex;
 flex-direction: column;
 align-items: center;
 margin:2rem 0;
  }

  .about-us-column {
  
    max-width: 65ch;
    margin:0 2rem 0 ;
  
  }
  
  .about-us-column>*{
  

    margin-bottom: 1rem;
  }
  


  .about-us-link{
    color:rgb(var(--contrast));
    text-decoration: none;
  }

  .landing-spinner-wrapper{
    position: absolute;
    z-index: 10;
    background-color: rgb(var(--bg));
    height:100vh;
    width: 100vw;
    display: grid;
    place-items: center;
    animation: fadeOut .3s linear 2s 1 forwards;
  }



.fill-font-wrapper{
  position: relative;
}

.fill-font{
  font-weight: 500;
}

  .fill-font:nth-child(1){
  color:rgb(var(--contrast))
  }

  .fill-font:nth-child(2){
    color:rgb(var(--accent));
    position: absolute;
    left:0;
  animation: fillFont 2s ease-out .3s 1 both; 

  }
  



  .landing-spinner-wrapper.loaded  .fill-font:nth-child(2){
   
  animation: fillFont .3s ease-out 1 forwards; 

  }


  .landing-spinner-wrapper.done {
  
    animation: fadeOut .3s linear 1 forwards;
  }