@keyframes gradientOscillation {
  0% {
    transform: translateX(-50%);
  }

  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}



.chat-screen {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(var(--bg));
}
.chat-screen__top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;

  .close-button {
    margin: 0 1rem 0 0;
  }
}

.chat-screen__top-bar-tokens-button {
  height: 2.5rem;

  border-radius: 2.5rem;

  padding: 0 1rem;
  background-color: rgba(var(--contrast), 0);

  &:hover {
    background-color: rgba(var(--contrast), 0.1);
  }
}
.chat-screen__row {
  flex: 1;

  display: flex;
  overflow: hidden;
  position: relative;
}

.chat-screen__threads {
  position: absolute;
  height: 100%;
  z-index: 2;
  width: 260px;
  background-color: rgb(var(--bg));
  display: flex;
  flex-direction: column;
}
.chat-screen__threads-inner-top {
  flex: 1;

  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chat-screen__threads-inner-top-scroll-wrapper {
  height: 100%;
  overflow-y: auto;
}
.chat-screen__threads-inner-top-scroll {
  flex: 1;
}
.chat-screen__account-link {
  display: flex;
  align-items: center;
  width: calc(100% - 4rem);
  border-radius: 2.5rem;
  margin: 0.5rem;
  padding: 0;
  background-color: rgba(var(--contrast), 0);

  .pfp {
    margin-right: 1rem;
  }

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.chat-screen__thread {
  display: flex;
  button {
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
}

.chat-screen__thread:hover {
  button {
    background-color: rgba(var(--contrast), 0.05);
  }
}
.chat-screen__thread.active {
  button {
    background-color: rgba(var(--contrast), 0.2);
  }
}

.chat-screen__thread-button {
  width: calc(100% - 1rem);
  text-align: left;
  background-color: transparent;
  border:solid 1px transparent;
  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.chat-screen__thread-button.active{
 
  border:solid 1px rgba(var(--accent), 1);
}

.chat-screen__threads.open {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}
.chat-screen__threads.closed {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.chat-screen__threads.open + .chat-screen__threads-spacer {
  width: 260px;
  transition: width 0.3s ease-in-out;
}

.chat-screen__threads.closed + .chat-screen__threads-spacer {
  width: 0px;
  transition: width 0.3s ease-in-out;
}

.chat-screen__threads-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  .accent-button {
    flex: 1;
    margin: 0.5rem;
  }

  &:last-child{
    padding: 0 0 1rem 0;
  }
}

.chat-container__outer {
  flex: 1;
  background-color: rgba(var(--contrast), 0.08);

  border-radius: 1rem 0 0 0;
  min-width: min(500px, calc(100% - 2rem));
  display: flex;
}
.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chat-container__history {
  position: relative;
  width: calc(100% - 4rem);
  flex: 1;

  overflow: scroll;

  padding:0em 2em 0em 2em;
  display: flex;
  justify-content: center;
}
.chat-container__inner {
  width: 100%;
  max-width: 62ch;
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
}
.chat-detail__container,
.chat-bot-response__container,
.chat-user-response__container {
  width: 100%;
  margin: 3rem 0 0 0;
}
.chat-details__date {
  flex: 1;
  font-weight: 100;
  font-size: 12px;
  font-family: var(--font-mono);
}

.chat-details__photo {
  background-color:rgb(var(--accent));
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 38px;
  overflow: hidden;
  margin-right: 1rem;
  img {
    width: 100%;
    height: 100%;
  }

  svg{
    width: 75%;
    margin: 6% 0 0 14%;
    fill: rgb(var(--bg));
  }
}
.chat-details__photo+div {
  opacity: .7;
}
.chat-detail__creator {
  display: flex;
  align-items: center;
}

.chat-details__ask {
  margin-top: 1rem;
}

.chat-bot-response__container {
  .chat-bot__response-bubble {
    display: flex;
    flex-direction: column;
    .chat-bot__response-text {
      margin-top: 1em;
    }
  }
}

.chat-bot__response-bubble-top,
.chat-user__response-bubble-top {
  display: flex;
  align-items: center;
}

.chat-user-response__container {
  .chat-user__response-bubble {
    display: flex;
    flex-direction: column;

    .chat-user__response-text {
      margin-top: 1em;
    }
  }
}


.input-wrapper{
  background-color: rgb(var(--bg));
  display: flex;
  justify-content: center;
  width:100% ;
}
.chat-user-input__container {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0.75rem;
  border-radius: 4rem;
  margin: 1rem;
  width: calc(100% - 3.5rem);
  max-width: 62ch;

  .chat-user-input__text-box {
    flex: 1;
    border: none;
    font-family: var(--font-grotesque);
    overflow: auto;
    margin: 0 1rem;

    &:focus {
      outline: none;
      border-bottom: 1px solid #929292;
    }
    .placeholder {
      color: #aaa;
    }
  }
 
  .chat-user-input__send.listening {
    background-color: red;
  }
  .chat-user-input__send {
    background-color: #979595;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.1s ease-in-out;
    color:rgb(var(--contrast));
    &:hover {
      background-color: #3d3d3d;
    }

    svg{
      fill:rgb(var(--contrast));
    }
  }
}
.chat-container__status-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.chat-container__articles {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.chat-container__article-button {
  padding: 0.5rem;
  border-radius: 0.25rem;
}
.chat-container__article-button.hidden {
  display: none;
}

.chat-container__article-button.visible {
  display: inline;
}
.chat-container__status {
  position: relative;
  height: 0.25rem;
  width: 100%;
}
.chat-container__status::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
}

.chat-container__status.awaiting_message::before,
.chat-container__status.finished::before {
  background: linear-gradient(
    90deg,
    rgba(var(--accent), 0) 0%,
    rgba(var(--accent), 1) 50%,
    rgba(var(--accent), 0) 100%
  );
  transform: translateX(0%);
  animation: gradientOscillation 3s ease-in-out infinite alternate;
}

.chat-container__status.in_progress::before {
  background: linear-gradient(
    90deg,
    rgba(var(--accent), 0) 0%,
    rgba(var(--accent), 1) 50%,
    rgba(var(--accent), 0) 100%
  );
  transform: translateX(0%);
  animation: gradientOscillation 1s ease-in-out infinite alternate;
}

.chat-container__status.failed::before {
  background: linear-gradient(
    90deg,
    rgba(var(--red), 0) 0%,
    rgba(var(--red), 1) 50%,
    rgba(var(--red), 0) 100%
  );
  transform: translateX(0%);
  animation: gradientOscillation 1s ease-in-out infinite alternate;
}

.chat-bot__response-text {
  & code {
    background-color: rgba(var(--accent), 0.1);
    display: flex;
    max-width: 100%;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    padding: 1rem;
    border-radius: 1rem;
    font-style: italic;
  }
}



.feedback-container{
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  border: solid 1px rgba(var(--contrast), .2);
  margin: 1rem 0 ;
}

.feedback-title{
  opacity: .7;
}

.feedback-container>div{

  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-thumbs{
  padding: .25rem;
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  margin:.5rem;
}

.feedback-thumbs.up.active{
  background-color:var(--green);
}

.feedback-thumbs.down.active{
  background-color: var(--red);
}

.feedback-input{
  border-radius: .25rem;
  background: rgba(var(--contrast),.1);
  color: rgba(var(--contrast),1);
  border: solid 1px rgba(var(--contrast), .2);
  margin: 0 0 .5rem 0;
}

.feedback-input::placeholder {
  color: rgba(var(--contrast),.7);
  opacity: 1; /* Firefox */
}

.error-type{
  margin:1rem 0 0;
 font-size: .8rem;
 opacity: .7;
}

.fecha{
  opacity: .7;
}
.truncate{
  width: 25ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.spinnerMessage{
  margin: 1rem 0;
}